import { Button, Tooltip } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import MicOffIcon from '@mui/icons-material/MicOff';
import { t } from 'i18next';

const MuteToggleButton = ({ isMuted, handleMuteToggle }) => {
    return (
        <Tooltip title={isMuted ? t('unmute') : t('mute')} placement="top">
            <Button onClick={handleMuteToggle} variant="contained" color={isMuted ? "secondary" : "primary"}>
                {isMuted ? <KeyboardVoiceIcon /> : <MicOffIcon />}
            </Button>
        </Tooltip>



    );
};

export default MuteToggleButton;