import { Button, Tooltip } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { t } from 'i18next';

const MuteToggleButton = ({ handleHangup }) => {
    return (
        <Tooltip title={t('hangup')} placement="right">
            <Button onClick={handleHangup} variant="contained" color="error">
                <PhoneIcon />
            </Button>
        </Tooltip >



    );
};

export default MuteToggleButton;