export const saveCredentialsToStorage = (credentials) => {
  localStorage.setItem('sipCredentials', JSON.stringify(credentials));
};

export const getCredentialsFromStorage = () => {
  const stored = localStorage.getItem('sipCredentials');
  return stored ? JSON.parse(stored) : null;
};

export const clearCredentialsFromStorage = () => {
  localStorage.removeItem('sipCredentials');
};