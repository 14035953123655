import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar, Menu, MenuItem, Button, Box, Typography, Modal, Paper } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SipService from './SipService';
import { getCredentialsFromStorage, clearCredentialsFromStorage } from '../utils/storage';
import { t } from 'i18next';

const Status = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState('offline'); // Начальный статус
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const tryReconnect = async () => {
    const credentials = getCredentialsFromStorage();

    if (credentials) {
      setLoading(true)
      const success = await SipService.connect(credentials);
      if (success) {
        setStatus('online');
        setLoading(false)
        SipService.makeCall('100');
      } else {
        setAlert('Не удалось восстановить соединение. Пожалуйста, войдите заново.');
        setLoading(false)
        handleLogout();
      }
    } else {
      handleLogout();
    }
  };

  const handleLogout = () => {
    SipService.goOffline(); // Завершить SIP-сессию
    clearCredentialsFromStorage(); // Удалить сохранённые данные
    navigate('/login'); // Редирект на логин
  };

  const handleStatusChange = async () => {
    if (status === 'offline') {
      setAnchorEl(null);
      tryReconnect()
    } else {
      setIsModalOpen(true); // Открыть модальное окно
    }
  };


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const confirmGoOffline = () => {
    SipService.goOffline();
    setStatus('offline');
    setIsModalOpen(false); // Закрыть модальное окно
    handleMenuClose();
  };

  const cancelGoOffline = () => {
    setIsModalOpen(false);
    setAnchorEl(null);
  };


  return <>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* Кнопка открытия меню */}
      <LoadingButton
        color="white"
        variant="outlined"
        sx={{
          backgroundColor: status === 'online' ? 'green' : 'gray',
          color: 'white',
          marginRight: 2,
        }}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleMenuOpen}
        loading={isLoading}
      >
        {status === 'online' ? t('online'): 'Offline'}
      </LoadingButton>
      {/* Выпадающее меню */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'status-button',
        }}
      >
        <MenuItem onClick={() => handleStatusChange(true)} disabled={status === 'online'}>
          {t('online')}
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange(false)} disabled={status === 'offline'}>
          {t('offline')}
        </MenuItem>

      </Menu>
    </Box>
    <Modal
      open={isModalOpen}
      onClose={cancelGoOffline}
      aria-labelledby="offline-modal-title"
      aria-describedby="offline-modal-description"
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 3,
          width: 300,
          textAlign: 'center',
        }}
      >
        <Typography id="offline-modal-title" variant="h6" gutterBottom>
          {t('warn')}
        </Typography>
        <Typography id="offline-modal-description" sx={{ mb: 2 }}>
          {t('warnDescription')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="error" onClick={confirmGoOffline}>
          {t('offline')}
          </Button>
          <Button variant="outlined" onClick={cancelGoOffline}>
          {t('cancel')}
          </Button>
        </Box>
      </Paper>
    </Modal>
    <Snackbar
      onClose={() => setAlert(false)}
      open={alert}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={() => setAlert(false)}
        severity="error"
        variant="filled"
        sx={{ width: '100%' }}
      >
        {alert}
      </Alert>
    </Snackbar>
  </>
};

export default Status;