import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar, TextField, Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SipService from './SipService';
import LanguageSelector from './LanguageSelector';
import { saveCredentialsToStorage } from '../utils/storage';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { i18n } = useTranslation();

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (login && password) {
      setLoading(true);
      const success = await SipService.connect({ login, password });
      if (success) {
        saveCredentialsToStorage({ login, password });
        setLoading(false);
        navigate('/dashboard');
      } else {
        setAlert(t('signInError'))
        setLoading(false);
      }
    } else {
      setAlert(t('fillInputs'));
    }
  };


  console.log('setup', i18n?.language)
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh', // Занять всю высоту экрана
        }}
      >
        <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
          <LanguageSelector />
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 300,
            padding: 3,
            border: '1px solid #ccc',
            borderRadius: 2,
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >

          <Typography variant="h5" mb={3}>
            {t('signIn')}
          </Typography>
          <form onSubmit={handleLogin} noValidate autoComplete="off">
            <TextField
              label="SIP login"
              variant="outlined"
              fullWidth
              margin="normal"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              focused
              required
            />
            <TextField
              label="SIP password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              focused
              required
            />
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              loading={isLoading}
              sx={{ mt: 2 }}
            >
              {t('signInButton')}
            </LoadingButton>
          </form>
        </Box>
        <Snackbar
          onClose={() => setAlert(false)}
          open={alert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setAlert(false)}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {alert}
          </Alert>
        </Snackbar>
      </Box>

    </>
  );
};

export default Login;