import { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import SipService from './SipService';
import Header from './Header';
import ClientModal from './ClientModal';
import HangupButton from './HangupButton';
import MuteToggleButton from './MuteToggleButton';
import { getCredentialsFromStorage } from '../utils/storage';
import { useTranslation } from 'react-i18next';

const data = {
  task_id: "10035",
  campaign_id: "917",
  additional_fields: {
    "Account Number": "79879387476",
    "Client Name": "Даша Клиент 3",
    "Primary Contact Number 1": "88005553535",
    "Other Contact Number 1": "88005552211",
    "Other Contact Name 1": "Cist Yana",
    "Other Contact Number 2": "555",
    "Other Contact Name 2": "Vlad",
    "Total Debt": "5500",
    DPD: "1",
    LifetimeID: "2",
    BucketID: "3",
    Email: "golovachlena@mail.ru",
    Agent: "12",
    "Status contract": "Succes",
    Comment: "Dolg 10000 trillionov"
  }
};

const Dashboard = () => {
  const audioRef = useRef(null);
  const { t } = useTranslation(); // Подключение перевода

  const [isMuted, setIsMuted] = useState(false); // Состояние микрофона

  const [clientData, setClientData] = useState(null); // Текущие данные клиента
  const [modalOpen, setModalOpen] = useState(false);
  const [collapsedTasks, setCollapsedTasks] = useState([]); // Список свернутых карточек

  // const [lastTaskId, setLastTaskId] = useState(null); // Хранение task_id для проверки
  const [taskHistory, setTaskHistory] = useState([]); // Хранение истории task_id

  const credentials = getCredentialsFromStorage();

  // const tryReconnect = async () => {
  //   const credentials = getCredentialsFromStorage();

  //   if (credentials) {
  //     const success = await SipService.connect(credentials);
  //     if (success) {
  //       setStatus('online');
  //     } else {
  //       alert('Не удалось восстановить соединение. Пожалуйста, войдите заново.');
  //       handleLogout();
  //     }
  //   } else {
  //     handleLogout();
  //   }
  // };

  useEffect(() => {
    SipService.setAudioElement(audioRef.current); // Устанавливаем аудио-элемент
  }, []);

  // useEffect(() => {
  //   const handleNewMessage = (data) => {

  //     console.log('useEffectuseEffectuseEffectuseEffect', data)
  //     if (data?.task_id && !taskHistory.includes(data.task_id)) {
  //       console.log('useEffect NEW DATA', taskHistory)
  //       setTaskHistory((prev) => [...prev, data.task_id + data.campaign_id]);
  //       setClientData(data);
  //       setModalOpen(true);
  //     }
  //   };

  //   SipService.onNewMessage(handleNewMessage); // Подписываемся на новые сообщения

  //   return () => {
  //     // Очистка обработчика при размонтировании компонента
  //     SipService.messageHandlers = SipService.messageHandlers.filter(handler => handler !== handleNewMessage);
  //   };
  // }, [taskHistory]);

  // const handleStatusChange = async () => {
  //   if (status === 'offline') {
  //     tryReconnect()
  //   } else {
  //     SipService.goOffline();
  //     setStatus('offline');
  //   }
  // };

  // const call = () => {
  //   SipService.makeCall('100'); // Звонок на номер 100
  // }

  // const handleLogout = () => {
  //   SipService.goOffline(); // Завершить SIP-сессию
  //   clearCredentialsFromStorage(); // Удалить сохранённые данные
  //   navigate('/login'); // Редирект на логин
  // };

  const handleHangup = () => {
    console.log('check allow hangup')
    if (SipService?.ua) {
      SipService.dtmf('9')
    }
  };

  const handleMuteToggle = () => {
    debugger
    if (SipService?.currentSession) {
      isMuted ? SipService.currentSession.unmute({ audio: true }) : SipService.currentSession.mute({ audio: true });
      setIsMuted(!isMuted);
    }
  };


  // Обработчик нового сообщения
  const handleNewMessage = (data) => {
    if (data?.task_id && data.additional_fields) {
      const isTaskProcessed = taskHistory.includes(data.task_id);
      if (!isTaskProcessed) {
        setTaskHistory((prev) => [...prev, data.task_id]); // Добавляем task_id в историю
        setClientData(data); // Сохраняем данные клиента
        setModalOpen(true); // Открываем модальное окно
      }
    }
  };

  useEffect(() => {
    SipService.onNewMessage(handleNewMessage);

    return () => {
      SipService.onNewMessage(() => { }); // Очищаем обработчик
    };
  }, [taskHistory]);



  // Свернуть карточку клиента
  const handleCollapse = (taskId) => {
    setCollapsedTasks((prev) => [...prev, taskId]);
    setModalOpen(false);
  };

  // Открыть карточку клиента заново
  const handleExpand = (taskId) => {
    const task = taskHistory.find((id) => id === taskId);
    if (task) {
      setCollapsedTasks((prev) => prev.filter((id) => id !== taskId)); // Убираем из списка свернутых
      setModalOpen(true);
    }
  };




  return (
    <>
      <Header />

      <Box sx={{ padding: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: 2 }}>
            <h3>{t('operator')} {credentials?.login}</h3>
          </Box>

          <MuteToggleButton isMuted={isMuted} handleMuteToggle={handleMuteToggle} />
        </Box>

        {clientData?.task_id ? t('inConversation') :  t('waitTask')}
        <Box sx={{ mt: 2 }}>
          <HangupButton handleHangup={handleHangup} />
        </Box>
        {/* Модальное окно клиента */}
        <ClientModal open={modalOpen} data={clientData} onCollapse={() => handleCollapse(clientData.task_id)} />


        {/* Карточка клиента */}
        {/* <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxWidth: 500,
              width: '100%',
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Карточка клиента
            </Typography>
            {clientData && (
              <Box sx={{ mt: 2 }}>
                <Typography>Task ID: {clientData.task_id}</Typography>
                <Typography>Campaign ID: {clientData.campaign_id || 'N/A'}</Typography>
                <Typography>Client Name: {clientData.additional_fields?.['Client Name'] || 'N/A'}</Typography>
                <Typography>Total Debt: {clientData.additional_fields?.['Total Debt'] || 'N/A'}</Typography>
                <Button
                  variant="text"
                  onClick={() => handleCollapse(clientData.task_id)}
                  sx={{ mt: 2 }}
                >
                  Свернуть
                </Button>
              </Box>
            )}
          </Box>
        </Modal> */}

        {/* Список свернутых карточек */}
        <Box sx={{ mt: 4 }}>
          {collapsedTasks.map((taskId) => (
            <Box
              key={taskId}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                border: '1px solid #ccc',
                borderRadius: 2,
                mb: 2,
              }}
            >
              <Typography>{t('clientCard.taskId')}: {taskId}</Typography>
              <Button variant="text" onClick={() => handleExpand(taskId)}>
                {t('expand')}
              </Button>
            </Box>
          ))}
        </Box>


        <audio ref={audioRef} style={{ display: 'none' }} />
      </Box>
    </>
  );
};

export default Dashboard;