import * as React from "react"

const RuFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={40}
    height={40}
    viewBox="0 0 512 512"
    {...props}
  >
    <g
      style={{
        stroke: "#000",
        strokeOpacity: 1,
      }}
    >
      <circle
        cx={259}
        cy={257}
        r={256}
        style={{
          fill: "#f0f0f0",
          stroke: "#000",
          strokeOpacity: 1,
        }}
      />
      <path
        d="M499.077 346.043C509.368 318.31 515 288.314 515 257c0-31.314-5.632-61.31-15.923-89.043H18.923C8.633 195.69 3 225.686 3 257c0 31.314 5.633 61.31 15.923 89.043L259 368.304Z"
        style={{
          fill: "#0052b4",
          stroke: "#000",
          strokeOpacity: 1,
        }}
      />
      <path
        d="M259 513c110.071 0 203.906-69.472 240.077-166.957H18.923C55.094 443.528 148.929 513 259 513Z"
        style={{
          fill: "#d80027",
          stroke: "#000",
          strokeOpacity: 1,
        }}
      />
    </g>
  </svg>
)
export default RuFlag
