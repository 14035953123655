import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { getCredentialsFromStorage } from './utils/storage';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const credentials = getCredentialsFromStorage();
    if (credentials) {
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default App;