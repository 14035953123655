import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SipService from './SipService';
import Status from './Status';
import LanguageSelector from './LanguageSelector';
import { getCredentialsFromStorage, clearCredentialsFromStorage } from '../utils/storage';
import { Logout } from '@mui/icons-material'
import PersonImg from '../img/avatar.png';
import { AppBar, Toolbar, Button, IconButton, Typography, Box, Avatar, Menu, MenuItem } from '@mui/material';
import { t } from 'i18next';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const credentials = getCredentialsFromStorage();

  const handleLogout = () => {
    SipService.goLogout(); // Завершить SIP-сессию
    clearCredentialsFromStorage(); // Удалить сохранённые данные
    navigate('/login'); // Редирект на логин
  };


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  return (
    <AppBar position="static" sx={{ backgroundColor: '#1976d2' }}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Dashboard
        </Typography>

        <Status />

        <Box sx={{ marginLeft: 2, display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }}>{credentials?.login}</Typography>
          <Avatar
            sx={{ cursor: 'pointer' }}
            alt={credentials?.login}
            src={PersonImg}
            onClick={handleMenuOpen}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>
              <Logout sx={{ mr: 1 }} /> {t('logout')}
            </MenuItem>
          </Menu>
          <LanguageSelector />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;