import { Box } from '@mui/material';
import RuFlag from '../img/ru';
import EnFlag from '../img/en';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation(); // Подключение перевода

  // Смена языка
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Box sx={{ ml: 2 }} style={{ cursor: 'pointer' }}>
      {i18n.language === 'ru' ? <RuFlag onClick={() => changeLanguage('en')} /> : <EnFlag onClick={() => changeLanguage('ru')} />}
    </Box >
  );
};

export default LanguageSelector;